import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-blog/src/components/layout"
import Bio from "gatsby-theme-blog/src/components/bio"
import { css } from "theme-ui"
import SEO from "../components/seo"
import Button from "../components/button"

const VideoGalleryPage = ({ data }) => {
  const loadPages = (loadedLength, numToLoad) => {
    const newLength = loadedLength + numToLoad
    if (newLength > data.videos.pageInfo.itemCount) {
      return data.videos.pageInfo.itemCount
    }
    return newLength
  }

  const [{ loadedLength }, setState] = useState({
    loadedLength: loadPages(0, 3),
  })

  return (
    <Layout
      location={{ pathname: "video-gallery" }}
      title={data.site.siteMetadata.title}
    >
      <SEO title="Video Gallery" />
      <div
        css={css({
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "700px",
        })}
      >
        {data.videos.edges.splice(0, loadedLength).map(({ node }) => (
          <video
            css={css({
              height: `270px`,
              pointerEvents: `none`,
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "480px",
            })}
            muted
          >
            <source src={node.ffmpeg.mp4.src} type={"video/mp4"} />
            <source src={node.ffmpeg.webm.src} type={"video/webm"} />
          </video>
        ))}
        <Button
          css={css({
            left: "50%",
            position: "relative",
            marginLeft: "-96px",
            width: "192px",
          })}
          disabled={loadedLength >= data.videos.pageInfo.itemCount}
          onClick={() => setState({ loadedLength: loadPages(loadedLength, 3) })}
        >
          More Videos ↓
        </Button>
      </div>
      <Bio></Bio>
    </Layout>
  )
}

export default VideoGalleryPage
export const pageQuery = graphql`
  query VideoGalleryPageImages {
    site {
      siteMetadata {
        title
      }
    }
    videos: allFile(filter: { relativeDirectory: { glob: "video/*" } }) {
      pageInfo {
        itemCount
      }
      edges {
        node {
          ffmpeg: childVideoFfmpeg {
            mp4: transcode(
              maxHeight: 270
              fileExtension: "mp4"
              codec: "libx264"
              options: [["-profile:v", "main"], ["-pix_fmt", "yuv420p"]]
              outputOptions: ["-movflags faststart"]
            ) {
              src
              height
            }
            webm: transcode(
              codec: "libvpx-vp9"
              maxHeight: 270
              fileExtension: "webm"
              outputOptions: ["-crf 20", "-b:v 0"]
            ) {
              src
              height
            }
          }
        }
      }
    }
  }
`
